import {Action} from './actions.js';
import {WordsEditor} from './words-editor.js';
import {Translator} from './translator.js';
import {ActivityModal} from './activity-modal.js';
import {AudioPlayer} from './audio-player.js';
import {Header} from './header.js';
import {InfoLayer} from './info-layer.js';
import {Draggable} from './draggable.js';
import {Navbar} from './navbar.js';
import {Sidebar} from './sidebar.js';
import {MainData} from './mainData.js';
import {Marks} from './marks.js';
import ResizeObserver from 'resize-observer-polyfill';
import {Notify} from "./notify";
import {Exercises} from "@/js/exercises";
import {Modal} from "@/js/modal";

import {datadogRum} from '@datadog/browser-rum';
import {distinctUntilChanged, Subject} from 'rxjs';
import {UiModal} from "@/js/ui-modal";

export class Pdf {
	constructor() {}
}
(function () {
	//teacher מורה117 -
	// let tmpToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjIxMzAzLCJyb2xlIjoiVVNFUl9UWVBFX1RFQUNIRVIiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoxLCJuYmYiOjE2MTA1NDgxMjksImV4cCI6MTYyNjEwMDEyOSwiaWF0IjoxNjEwNTQ4MTI5LCJpc3MiOiJZQkJfSnNvbldlYlRva2VuU2VydmVyIiwiYXVkIjoiaHR0cHM6Ly90ZXN0d2l6ZGlhcGkuYXp1cmV3ZWJzaXRlcy5uZXQifQ.AgxMjCP9chgYZH8V3QcLKKYKmaEAFxKq1YmKE6DSspE';
	//other teacher 9 -
	// let tmpToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjE4LCJyb2xlIjoiVVNFUl9UWVBFX1RFQUNIRVIiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoxLCJuYmYiOjE2MTA1MjkzODcsImV4cCI6MTYyNjA4MTM4NywiaWF0IjoxNjEwNTI5Mzg3LCJpc3MiOiJZQkJfSnNvbldlYlRva2VuU2VydmVyIiwiYXVkIjoiaHR0cHM6Ly90ZXN0d2l6ZGlhcGkuYXp1cmV3ZWJzaXRlcy5uZXQifQ.fEzxwYCiwBtFd7aZEP-ukv3q2kCvBjwOeJNwvasly3M';
	//student -
	// let tmpToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjQ3Mjk2NCwicm9sZSI6IlVTRVJfVFlQRV9TVFVERU5UIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy91c2VyZGF0YSI6MSwibmJmIjoxNjEwMzY0NzMwLCJleHAiOjE2MjU5MTY3MzAsImlhdCI6MTYxMDM2NDczMCwiaXNzIjoiWUJCX0pzb25XZWJUb2tlblNlcnZlciIsImF1ZCI6Imh0dHBzOi8vdGVzdHdpemRpYXBpLmF6dXJld2Vic2l0ZXMubmV0In0.dV3AxWNBRWOVhtLyDfKPfuP6G-4fp52MTyl0FaVg7-4';
	//editor editor9 -
	// let tmpToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjQxNDAxMSwicm9sZSI6IlVTRVJfVFlQRV9FRElUT1IiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoxLCJuYmYiOjE2MTA1Mjk0MDYsImV4cCI6MTYyNjA4MTQwNiwiaWF0IjoxNjEwNTI5NDA2LCJpc3MiOiJZQkJfSnNvbldlYlRva2VuU2VydmVyIiwiYXVkIjoiaHR0cHM6Ly90ZXN0d2l6ZGlhcGkuYXp1cmV3ZWJzaXRlcy5uZXQifQ.npQWUqahNlR860OWl8p6BSbU2lx6RUv5VFh8gyJgGMw';
	// other editor editor14-
	// let tmpToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjQxNDAxNSwicm9sZSI6IlVTRVJfVFlQRV9FRElUT1IiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoxLCJuYmYiOjE2MTA1NDYzNDUsImV4cCI6MTYyNjA5ODM0NSwiaWF0IjoxNjEwNTQ2MzQ1LCJpc3MiOiJZQkJfSnNvbldlYlRva2VuU2VydmVyIiwiYXVkIjoiaHR0cHM6Ly90ZXN0d2l6ZGlhcGkuYXp1cmV3ZWJzaXRlcy5uZXQifQ.espCMnrLOIFbhiw6aPHR20APDCH3NOVdFoDQM6aj_dE';
	// MainData.token = (queryToken !== null) ? queryToken : tmpToken;

	MainData.subActivePage$ = new Subject();
	MainData.initedWordsArr = {};

	if (window.location.hostname == 'books.wizdi.school') {
		MainData.environment = {
			wizdi: 'https://wizdi.school/',
			api: 'https://api.wizdi.school/',
			coreApi: 'https://apicore.wizdi.school/',
			idm: 'https://jwt.wizdi.school/',
			pdfApi: 'https://api-books.wizdi.school/',
			booksServer: 'https://books.wizdi.school/',
			lrs: 'https://api-lrs.wizdi.school/',
			annApp: 'https://ann-education-wizdi-tools.web.app/ex',
			dataDogConfig: {
				applicationId: 'b6b83dc3-ccc1-4662-8302-1d98c1f470e4',
				clientToken: 'pub97e5f3216c8908b9609796a29aebb2ae',
				site: 'datadoghq.eu',
				service:'prod-wizdi-pdfviewer',
				env:'Prod',
			},
			media: 'https://media.wizdi.school/',
			webHook: 'https://webhook.wizdi.school/'
		}
	} else {
		MainData.environment = {
			wizdi: 'https://test.wizdi.school/',
			// todo: for testing locally
			// wizdi: 'http://localhost:4200/',
			api: 'https://apitest.wizdi.school/',
			coreApi: 'https://apicoretest.wizdi.school/',
			idm: 'https://jwttest.wizdi.school/',
			pdfApi: 'https://api-test-books.wizdi.school/',
			booksServer: 'https://test-books.wizdi.school/',
			lrs: 'https://api-lrs-test.wizdi.school/',
			annApp: 'https://ann-education-wizdi-tools.web.app/ex',
			dataDogConfig: {
				applicationId: '4aa24ec2-bcdd-4452-b53d-07dc5ca30ee1',
				clientToken: 'pub90f1b160c190616c8610f6375bac0bd9',
				site: 'datadoghq.eu',
				service:'test1-wizdi-pdfviewer',
				env:'Test1',
			},
			media: 'https://apicoretest.wizdi.school/',
			webHook: 'https://test-webhook.wizdi.school/'
		}
		// Test2
		// MainData.environment = {
		// 	wizdi: 'https://test2.wizdi.school/',
		// 	api: 'https://test2-api.wizdi.school/',
		// 	coreApi: 'https://test2-api-core.wizdi.school/',
		// 	idm: 'https://test2-jwt.wizdi.school/',
		// 	pdfApi: 'https://api-test-books.wizdi.school/',
		// 	booksServer: 'https://test-books.wizdi.school/',
		// 	lrs: 'https://api-lrs-test2.wizdi.school/',
		// 	annApp: 'https://ann-education-wizdi-tools.web.app/ex',
		// 	dataDogConfig: {
		// 		applicationId: '4aa24ec2-bcdd-4452-b53d-07dc5ca30ee1',
		// 		clientToken: 'pub90f1b160c190616c8610f6375bac0bd9',
		// 		site: 'datadoghq.eu',
		// 		service:'test1-wizdi-pdfviewer',
		// 		env:'Test1',
		// 	},
		// 	media: 'https://media.wizdi.school/',
		// 	webHook: 'https://test-webhook.wizdi.school/'
		// }
	}

	datadogRum.init({
		applicationId: MainData.environment['dataDogConfig'].applicationId,
		clientToken: MainData.environment['dataDogConfig'].clientToken,
		site: MainData.environment['dataDogConfig'].site,
		service: MainData.environment['dataDogConfig'].service,
		env: MainData.environment['dataDogConfig'].env,
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel:'mask-user-input'
	});

	datadogRum.startSessionReplayRecording();


	MainData.userTypesInterface = {
		67: 'teacher',
		68: 'student',
		69: 'editor',
		70: 'admin',
		22668: 'school_stuff'
	}

	const urlToRedirect = window.location.href;
	const urlParams = new URLSearchParams(window.location.search);
	const queryBookId = urlParams.get("bookId");
	MainData.bookId = queryBookId !== null ? queryBookId : '353';
	MainData.isMissionCreated =  urlParams.get("missionCreated");
	MainData.isShowOnlyAnnExercisePages = urlParams.get("isShowOnlyAnnExercisePages");
	let queryPage = urlParams.get("page");

	const activitiesFromPdf = urlParams.get('activities');
	const exercisesFromPdf = urlParams.get('exercises');
	if (queryBookId && (activitiesFromPdf || exercisesFromPdf)) {
		const data = {
			bookId: queryBookId,
			activities: activitiesFromPdf
				? activitiesFromPdf.split(',').map((item) => {
					const el = item.split('-');
					return { objectMetadataId: +el[0], activityTypeId: +el[1] };
				})
				: [],
			annExercises: exercisesFromPdf ? exercisesFromPdf.split(',').map((el) => +el) : [],
		};
		localStorage.setItem('taskSelectedObjects', JSON.stringify([data]));
	}
	if (activitiesFromPdf || exercisesFromPdf) {
		history.replaceState({}, "", queryBookId ? `?bookId=${queryBookId}` : '');
	}

	if (MainData.isMissionCreated) {
		history.replaceState({}, "", queryBookId ? `?bookId=${queryBookId}` : '');
		Sidebar.prototype.resetSelectedItems();
	}
	if (queryPage) {
	  // MainData.currentPage = parseInt(queryPage) + 2;
		MainData.currentPage = parseInt(queryPage) + 1;
	  history.replaceState({}, "", queryBookId ? `?bookId=${queryBookId}` : '');
	} else {
	  // MainData.currentPage = 1;
		MainData.currentPage = 0;
	}
	MainData.subActivePage$.next(MainData.currentPage);

	const wizdiFrame = document.createElement('iframe');
	wizdiFrame.id = 'wizdiFrame';
	wizdiFrame.src = MainData.environment.wizdi + 'blank-page';
	wizdiFrame.style.display = "none";
	const body = document.querySelector('body');
	body.appendChild(wizdiFrame);

	MainData.translator = new Translator(1);
	const tempData = { data: JSON.stringify(
			{
				// teacher------
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjQ4ODIwMSwicm9sZSI6IlVTRVJfVFlQRV9URUFDSEVSIiwibGFuZ3VhZ2VJZCI6IjEiLCJjdXJyZW50U2Nob29sSWQiOiIxMTE4OCIsIm5iZiI6MTcwMzA3ODI5OSwiZXhwIjoxNzE4NjMwMjk5LCJpYXQiOjE3MDMwNzgyOTksImlzcyI6IllCQl9Kc29uV2ViVG9rZW5TZXJ2ZXIiLCJhdWQiOiJodHRwczovL3Rlc3R3aXpkaWFwaS5henVyZXdlYnNpdGVzLm5ldCJ9.3_mSsEVjnU0hq3RSDxs58VOaUGvQ5JEwxgw7qmY4D4w",

        // student------
				// "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjcyMTI3Nywicm9sZSI6IlVTRVJfVFlQRV9TVFVERU5UIiwibGFuZ3VhZ2VJZCI6IjEiLCJjdXJyZW50U2Nob29sSWQiOiIxMDAyNyIsIm5iZiI6MTcwODM1MjM4MSwiZXhwIjoxNzIzOTA0MzgxLCJpYXQiOjE3MDgzNTIzODEsImlzcyI6IllCQl9Kc29uV2ViVG9rZW5TZXJ2ZXIiLCJhdWQiOiJodHRwczovL3Rlc3R3aXpkaWFwaS5henVyZXdlYnNpdGVzLm5ldCJ9.g5F79JIGCW1SakINsPoAiZfbQuvQvDLhYPMLRx6jWdU",

				// editor------
				// "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjQ0ODE0MSwicm9sZSI6IlVTRVJfVFlQRV9FRElUT1IiLCJsYW5ndWFnZUlkIjoiIiwiY3VycmVudFNjaG9vbElkIjoiIiwibmJmIjoxNzA4MDgxMDgxLCJleHAiOjE3MjM2MzMwODEsImlhdCI6MTcwODA4MTA4MSwiaXNzIjoiWUJCX0pzb25XZWJUb2tlblNlcnZlciIsImF1ZCI6Imh0dHBzOi8vdGVzdHdpemRpYXBpLmF6dXJld2Vic2l0ZXMubmV0In0.Jsh_sdWRsGGyTHFO8lFyQARhHoW_tBr_2ais8IiNO6s",
				// "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjQxNDAxOCwicm9sZSI6IlVTRVJfVFlQRV9FRElUT1IiLCJsYW5ndWFnZUlkIjoiIiwiY3VycmVudFNjaG9vbElkIjoiIiwibmJmIjoxNzE2MjA1MDY1LCJleHAiOjE3MzE3NTcwNjUsImlhdCI6MTcxNjIwNTA2NSwiaXNzIjoiWUJCX0pzb25XZWJUb2tlblNlcnZlciIsImF1ZCI6Imh0dHBzOi8vdGVzdHdpemRpYXBpLmF6dXJld2Vic2l0ZXMubmV0In0.AwvI_Mskdr8INHF2Jv4zPrK_Sy35kHTnIZfGtLyoBro",
				"classId": 1,
				"fromBookReturnUrl": null,
				"languageId": 1,
				"annToken": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY5NzUyNzE0OSwiZXhwIjoxNjk3NTMwNzQ5LCJpc3MiOiJhbm4tZWR1Y2F0aW9uLXdpemRpQGFwcHNwb3QuZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImFubi1lZHVjYXRpb24td2l6ZGlAYXBwc3BvdC5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoiZmQxV20xRmMzS08zVXVNcm55a0Q5Tjg4emI3MyJ9.DQ_a_wEEWh_tn1E25N8xu1MxnmIHK8TYqdHY73LDPZxB3HJ64R_Vnhgm9ITitAgCnPvwI_0Y51mdMVd0b9N-9c8vhf4M-6OF9RL-AB5fPd7yPTiD43ykEzOOsTmk1BJ6XkGcRRrTueLNf0TE1mKG46hekNPAyvuQ3DznuUugu3VQiq0nNOsEWSRBZnp7gdb4nKqUC-I7Gcv91575hoQjaV9yFpMOzC7qvAiv8JMmOY8Q_W_FoCfoG31vLVCZBRTAQKlRJHdfkCSUnZ6Z3-1l9W_waurUXn6grOKiFU4FbhPIDzTKsZ5E4MofrwAb87l4uziM6oZxOUnmoFqAvaOSSg",
				"token2": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjQ4ODIwMSwicm9sZSI6IlVTRVJfVFlQRV9URUFDSEVSIiwibGFuZ3VhZ2VJZCI6IjEiLCJjdXJyZW50U2Nob29sSWQiOiIxMDk1MSIsIm5iZiI6MTY5NzQ1ODI0NiwiZXhwIjoxNzEzMDEwMjQ2LCJpYXQiOjE2OTc0NTgyNDYsImlzcyI6IllCQl9Kc29uV2ViVG9rZW5TZXJ2ZXIiLCJhdWQiOiJodHRwczovL3Rlc3R3aXpkaWFwaS5henVyZXdlYnNpdGVzLm5ldCJ9.QyeBQjUx37QXe1VAcl2pebBL_BGabxgHZyQAwOOMdM0",
				"classId2": 1,
				"fromBookReturnUrl2": "",
				"languageId2": 1,
				"annToken2": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTY5NzUyNzE0OSwiZXhwIjoxNjk3NTMwNzQ5LCJpc3MiOiJhbm4tZWR1Y2F0aW9uLXdpemRpQGFwcHNwb3QuZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImFubi1lZHVjYXRpb24td2l6ZGlAYXBwc3BvdC5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoiZmQxV20xRmMzS08zVXVNcm55a0Q5Tjg4emI3MyJ9.DQ_a_wEEWh_tn1E25N8xu1MxnmIHK8TYqdHY73LDPZxB3HJ64R_Vnhgm9ITitAgCnPvwI_0Y51mdMVd0b9N-9c8vhf4M-6OF9RL-AB5fPd7yPTiD43ykEzOOsTmk1BJ6XkGcRRrTueLNf0TE1mKG46hekNPAyvuQ3DznuUugu3VQiq0nNOsEWSRBZnp7gdb4nKqUC-I7Gcv91575hoQjaV9yFpMOzC7qvAiv8JMmOY8Q_W_FoCfoG31vLVCZBRTAQKlRJHdfkCSUnZ6Z3-1l9W_waurUXn6grOKiFU4FbhPIDzTKsZ5E4MofrwAb87l4uziM6oZxOUnmoFqAvaOSSg",
			}
		)}
	// todo: for testing
	// getTokenFromWizdi(tempData);
	window.addEventListener('message', function (e) {
		if (e && e.data && e.data === "close-event"){
			Modal.closeModal();
		} else if(e && e.data && e.data === "renew_token"){
			const date = new Date();
			wizdiFrame.src = MainData.environment.wizdi + 'blank-page' + `?time=${date.getTime()}`;
		} else {
			// todo: comment for testing
			// if (MainData.environment.wizdi.includes(e.origin)) {
				getTokenFromWizdi(e);
			// }
		}
	});

	const resizeObserver = new ResizeObserver((entries) => {
		Action.prototype.setHeightdisplayPairs();
		const elWidth = entries[0].target.offsetWidth;
		const parentWidth = entries[0].target.parentNode.offsetWidth;
		if (elWidth/parentWidth >= 0.5) {
			const navbar = document.getElementById('navbar');
    		navbar.style.display = 'flex';
		}
		if (elWidth/parentWidth < 0.5) {
			const navbar = document.getElementById('navbar');
			navbar.style.display = 'none';
		}
	});
	resizeObserver.observe(document.querySelector('.conteiner-wrapper'));

	function getTokenFromWizdi(event) {
		const messageData = event?.data ? JSON.parse(event?.data) : null;

		if (messageData && (messageData.token || messageData.token2)) {
			console.log(messageData);
			const token = messageData.token || messageData.token2;
			const url = `${MainData.environment.idm}api/token/isvalid`;
			let tokenForCheck = {
				"key": token
			}
			tokenForCheck = JSON.stringify(tokenForCheck);
			let request = {};
			request.contentType = 'application/json';
			request.type = 'POST';
			request.data = tokenForCheck;
			request.url = url;
			request.success = (res) => {
				if (res.expirationDate && !res.isExpired) {
					MainData.token = (token !== null) ? token : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOjQxNDAxMSwicm9sZSI6IlVTRVJfVFlQRV9FRElUT1IiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoxLCJuYmYiOjE2MDI1NzA2MjEsImV4cCI6MTYwMjYwNjYyMSwiaWF0IjoxNjAyNTcwNjIxLCJpc3MiOiJZQkJfSnNvbldlYlRva2VuU2VydmVyIiwiYXVkIjoiaHR0cHM6Ly90ZXN0d2l6ZGlhcGkuYXp1cmV3ZWJzaXRlcy5uZXQifQ.tZadPdx9AdAOOpp_YubPtSK0LLmjfREGmuMY55sFbw8';
					MainData.classId = messageData.classId  || messageData.classId2;
					MainData.languageId = messageData.languageId || messageData.languageId2;
					MainData.fromBookReturnUrl = messageData.fromBookReturnUrl || messageData.fromBookReturnUrl2;
					MainData.annToken = messageData.annToken || messageData.annToken2;
					init();
					window.removeEventListener('message', getTokenFromWizdi);
				} else {
					showModal();
				}
			}
			request.processData = true;
			$.ajax(request);
		} else {
			if (messageData && messageData.token2 == "" ) {
				showModal();
			}
		}
	}

	function showModal() {
		localStorage.removeItem('taskSelectedObjects');
		const authModalWrapper = document.querySelector('.auth-modal-wrapper');
		authModalWrapper.style.display = 'flex';
		const btnGoLogin = authModalWrapper.querySelector('.btn-go-login');
		const wizdiFrame = document.getElementById('wizdiFrame');
		if (wizdiFrame) {
			wizdiFrame.contentWindow.postMessage(urlToRedirect || window.location.href, '*');
		}
		btnGoLogin.addEventListener('click', () => {
			btnGoLogin.style.pointerEvents = 'none';
			// const bookParams = {
			// 	bookId: MainData.bookId,
			// 	page: MainData.currentPage
			// }
			window.location.href = MainData.environment.wizdi + 'home';
		})

	}

	function pages(pagesArr) {
		if (!MainData.originalPageSize) {
			let img = new Image();
			img.onload = function(){
				MainData.originalPageSize = {
					width: this.width,
					height: this.height
				};
				Action.prototype.setHeightdisplayPairs();
			}
			img.src = `${MainData.environment.media}${pagesArr[0].imagePath}`;
		}

		const length = pagesArr.length;
		for (let i = 0; i < length; i++) {
			let element = $('<div></div>');
			const url = `${MainData.environment.media}${pagesArr[i].imagePath}`;
			element.attr('data-src', url);

			element.addClass('page');
			element.addClass('lazy');
			// element.attr('id', 'page' + pagesArr[i].number);
			element.attr('id', 'page' + i);
			element.attr('data-page-number', i);
			element.attr('data-page-id', pagesArr[i].pageId);
			if (MainData.userType !== "editor") {
				const bookmarkWrapper = createPageBookmarkEl(i);
				bookmarkWrapper.setAttribute('data-page-number', i);
				element.append(bookmarkWrapper);
			}
			if ((i + 1) % 2 == 0) {
				//$('.main-conteiner').append(element);
				$('.main-conteiner #displayPair' + (i - 1)).append(element);
			} else {
				//$('.main-conteiner').append(element);
				let display = $('<div>');
				display.addClass('displayPair');
				display.attr('id', 'displayPair' + i);
				$('.main-conteiner').append(display);
				$('.main-conteiner #displayPair' + i).append(element);
			}
		}
		if (MainData.userType === 'editor') {
			if (MainData.coordsData && MainData.coordsData.length > 0) {
				// MainData.wordsEditor.prepareWordsForEditor();
				const wordsEditorToggle = document.querySelector(".editor-toggle");
				wordsEditorToggle.classList.add("clickable");
			}
			// temp
			// const colorSelectorBtn = document.querySelector('.color-selector-btn');
			// const searchToggle = document.querySelector('.search-toggle');
			// colorSelectorBtn.style.display = 'block';
			// searchToggle.style.display = 'block';
		}
		setTimeout(() => {lazyImgLoad()}, 500);
	}

	function createPageBookmarkEl(page) {
		const bookmark = document.createElement('div');
		bookmark.classList.add('ui-bookmark');

		// const bookmark = document.createElement('div');
		// bookmark.classList.add('bookmark');

		const iconBookmark = document.createElement('i');
		iconBookmark.classList.add('icon4-multiple-bookmark-o');
		const iconBookmark2 = document.createElement('i');
		iconBookmark2.classList.add('icon4-multiple-bookmark');

		bookmark.appendChild(iconBookmark);
		bookmark.appendChild(iconBookmark2);
		// bookmarkWrapper.appendChild(bookmark);
		bookmark.addEventListener('click', () => { onCreateBookmark(page) });

		return bookmark;
	}

	function onCreateBookmark (page) {
		let bookmarkEvent = new CustomEvent('onBookmark', { detail: { page: page } });
		document.dispatchEvent(bookmarkEvent);
		MainData.sidebarObj.recalculateContentItems();
	}

	function init() {
		MainData.markColor = 'none';
		MainData.notify = new Notify();
		const modal = new Modal();
		MainData.UiModal = new UiModal();
		// console.log(UiModal);
		// UiModal.openUiModal('asdas dasd asd asd');
		// initUser();
		Promise.all([getUserData()]).then(() => {
			Promise.all([
				getBookmarksByBookId(),
				initCoordinates(),
				initBookNameAndBookmark(),
				initBookPages(),
				initBookChapters(),
				initInfoLayers(),
				initLinks(),
				initComments(),
				initMarks(),
				initBook(),
				Exercises.getExercises(),
				getBookChaptersAndSubjectsWithExercises(),
			]).then(([bookmarks, fromCoords, fromBookNameAndBookmark,
															fromBookPages, fromBookChapters,
															fromInfoLayers, fromLinks,
															fromComments, fromMarks,
															book, exercises,
															chaptersAndSubjectsWithExercises]) => {
				MainData.bookmarks = bookmarks.sort((a, b) => a.pageNumber - b.pageNumber);
				// console.log(chaptersAndSubjectsWithExercises);
				// console.log([...MainData.bookmarks]);
				if (chaptersAndSubjectsWithExercises.length) {
					const first = chaptersAndSubjectsWithExercises[0];
					MainData.bookmarks = MainData.bookmarks.filter((el) => {
						return el.pageNumber >= first.subjects[0]?.startPage && el.pageNumber <= chaptersAndSubjectsWithExercises[chaptersAndSubjectsWithExercises.length - 1].endPage
					});
				}
				// console.log([...MainData.bookmarks]);
				MainData.coordsData = fromCoords;
				MainData.subActivePage$
					.pipe(distinctUntilChanged())
					.subscribe((activePage) => {
						const page = document.querySelector(`[data-page-number="${activePage}"]`);
						const pageId = page.dataset.pageId;
						WordsEditor.prototype.preparingWordsForPage(pageId);
					});

				MainData.header = new Header(fromBookNameAndBookmark.name);
				MainData.book = book;
				MainData.book.bookChapters = chaptersAndSubjectsWithExercises;
				// console.log(MainData.book);
				// console.log(Sidebar.prototype.bookContent);

				MainData.header.isDisabledTopNav(MainData.book, MainData.classList);
				MainData.activities = fromInfoLayers.filter(act => act.isPublish).filter(item => MainData.userType === 'student' ? item.activityType.activityTypeId != 8 : true);
				MainData.bookChapters = fromBookChapters;
				MainData.bookPages = fromBookPages.pages.sort((a, b) => {
					return a.number - b.number
				});
				// if (MainData.userType == 'teacher') {
				if (MainData.isShowOnlyAnnExercisePages && exercises.length) {
					MainData.pageNambers = [];
					MainData.bookPages = MainData.bookPages.filter((page) => {
						const exPage = exercises.find((exercise) => exercise.pageId == page.pageId);
						if (exPage) {
							MainData.pageNambers.push(page.number);
						}
						return exPage;
					});
				}
				// }
				const defaultPage = {
					bookId: MainData.bookPages[0].bookId,
					data: [],
					imagePath: "",
					pageId: "",
					pageNumber: -2
				};
				//defaultPage,
				// pages([defaultPage,...MainData.bookPages]);

				// MainData.bookPages = MainData.bookPages.slice(0, 10);
				pages(MainData.bookPages);
				Sidebar.prototype.createBookContent(MainData.book);
				Sidebar.prototype.bookmarks$.next(MainData.bookmarks);

				MainData.sidebarObj = new Sidebar();
				MainData.navbarObj = new Navbar({ pagesAmount: fromBookPages.pages.length, bookmark: fromBookNameAndBookmark.bookmark });

				initActivityModal();

				Promise.all([syncLikes()]).then(() => {
					addActivitiesOnPages();
					lazyLoadActivityImage();
					function lazyLoadActivityImage(){
						const containerWrapper = document.querySelector(".conteiner-wrapper");
						lazyLoadActivitiesImage();
						function lazyLoadActivitiesImage() {
							const lazyloadImages = document.querySelectorAll(".info-layer-lazy");
							lazyloadImages.forEach(function(item) {
								const id = item.getAttribute('id');
								var position = document.getElementById(`${id}`).closest('.inner-wrapper').getBoundingClientRect();
								if(position.top < window.innerHeight && position.bottom >= 0) {
									item.classList.remove('info-layer-lazy');
									item.setAttribute("src", `${item.dataset.src}`);
									item.onload = function(){
										item.removeAttribute('data-src');
									};
								}
							});
							if (lazyloadImages.length === 0) {
								containerWrapper.removeEventListener("scroll", lazyLoadActivitiesImage);
							}
						}
						containerWrapper.addEventListener("scroll", lazyLoadActivitiesImage);
					}
				});

				MainData.marksData = fromMarks;
				MainData.audioPlayer = new AudioPlayer();
				// temp commented
				MainData.linksAndComments = [...fromLinks, ...fromComments];
				addLiknsCommentsOnPages();
				// temp
				// if (MainData.userType === 'editor') {
				// }
				Marks.prototype.addMarksOnPages();
				Marks.prototype.initWordsForMarks();
				//if (MainData.userType == 'teacher') {
        //   console.log(exercises);
					Exercises.addExercisesToBook(exercises);
				//}

				const split = window.Split(['.conteiner-wrapper', '.sidebar-block'], {
					sizes: [50, 50],
					minSize: [0, 50]
				})

				const containerWrapper = document.querySelector('.conteiner-wrapper');
				containerWrapper.style.width = '100%';
			})
		})
	}

	function initActivityModal() {
		MainData.activityModal = new ActivityModal();
		const modalWrapper = document.querySelector('.activity-modal-content-wrapper');
		modalWrapper.addEventListener('click', (e) => {
			e.stopPropagation();
			MainData.activityModal.closeActivityModal();
		});
	}

	function addLiknsCommentsOnPages() {
		if(MainData.linksAndComments && MainData.linksAndComments.length > 0) {
			for (let item of MainData.linksAndComments) {
				if (item.pageId) {
					const page = document.querySelector(`[data-page-id="${item.pageId}"]`);
					const drag = new Draggable(item, true);
					const el = drag.createElement();
					if (page) {
						page.appendChild(el);
					}
				}
			}
		}
	}

	function addActivitiesOnPages() {
		for (let item of MainData.activities) {
			const page = document.querySelector(`[data-page-id="${item.pageId}"]`)
			const infoLayer = new InfoLayer(item);
			const el = infoLayer.createElement();
			if (page) {
				page.appendChild(el);
			}
		}
	}


	function stripVowels(rawString) {
		var newString = '';
		for (let j = 0; j < rawString.length; j++) {
			if (rawString.charCodeAt(j) < 1425
				|| rawString.charCodeAt(j) > 1479) { newString = newString + rawString.charAt(j); }
		}
		return (newString);
	}

	function getUserData() {
		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.coreApi}api/User/GetById?bookId=${MainData.bookId}`;
			let request = {};
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
				MainData.user = res.responseObject.user;
				MainData.selectedClassListIds = [];
				const userTypeId = res.responseObject.user ? res.responseObject.user.luserType : res.responseObject.luserType;
				MainData.userType =  MainData.userTypesInterface[userTypeId];
				MainData.teacherUserId = res.responseObject.user.teacherUserId || [];
				MainData.userId = res.responseObject.user ? res.responseObject.user.id : res.responseObject.id;
				MainData.wordsEditor = new WordsEditor();
				const languageId = res.responseObject.user ? res.responseObject.user.languageId : res.responseObject.languageId;
				MainData.translator = new Translator(languageId);
				if (MainData.userType == 'teacher') {
					getClassList().then(() => {
						/*if (MainData.userType === 'student') {
              MainData.classList = res.responseObject.classList;
            }*/
						/*if (!MainData.classList || !MainData.classList.length){
               MainData.notify.show(MainData.langData["It is not possible to add a layer of information on top of a book of a layer to which you are not associated"], {delay: 7000});
            }*/
						resolve(res);
					});
				} else {
					MainData.classList = res.responseObject.classList;
					resolve(res);
				}
				// temp
				// if(MainData.userType !== 'editor') {
					// const colorSelectorBtn = document.querySelector('.color-selector-btn');
					// colorSelectorBtn.style.display = 'none';
				// }
			}
			request.processData = true;
			$.ajax(request);
		})
	}

	function getClassList() {
		MainData.classList = [];
		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.coreApi}api/ClassTeacher/GetRelatedToBook?bookId=${MainData.bookId}`;
			let request = {};
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
        MainData.classList = res.responseObject;
				resolve(res);
			}
			$.ajax(request);
		})
	}

	function initLinks() {
		return new Promise((resolve, reject) => {
			const classListIds = MainData.classList.map(classData => {
				return classData.classId;
			});
      if (classListIds.length) {
        let url = `${MainData.environment.pdfApi}link/get?bookId=${MainData.bookId}`;
        if (MainData.userType === 'student') {
          MainData.teacherUserId.map((el) => {
            url += `&teacherUserIdList=${el}`;
          })
        }
        if (classListIds && classListIds.length > 0) {
          classListIds.map((el) => {
            url += `&classIdList=${el}`;
          })
        }
        let request = {};
        request.type = 'GET';
        request.url = url;
        request.beforeSend = function (xhr) {
          xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
        };
        request.success = (res) => {
          resolve(res.items);
        }
        request.processData = false;
        $.ajax(request);
      } else {
        resolve([]);
      }
		})
	}

	function initComments() {
		return new Promise((resolve, reject) => {
			const classListIds = MainData.classList.map(classData => {
				return classData.classId;
			});
			if (classListIds.length) {
				let url = `${MainData.environment.pdfApi}comment/get?bookId=${MainData.bookId}`;
				if (MainData.userType === 'student') {
					MainData.teacherUserId.map((el) => {
						url += `&teacherUserIdList=${el}`;
					})
				}
				if (classListIds && classListIds.length > 0) {
					classListIds.map((el) => {
						url += `&classIdList=${el}`;
					})
				}
				let request = {};
				request.type = 'GET';
				request.url = url;
				request.beforeSend = function (xhr) {
					xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
				};
				request.success = (res) => {
					resolve(res.items);
				}
				request.processData = false;
				$.ajax(request);
			} else {
				resolve([])
			}
		})
	}

	function initMarks() {
		return new Promise((resolve, reject) => {
			const classListIds = MainData.classList.map(classData => {
				return classData.classId;
			});
			// if (classListIds.length) {
				let url = `${MainData.environment.pdfApi}mark/get?bookId=${MainData.bookId}`;
				let request = {};
        if (MainData.userType === 'student') {
          MainData.teacherUserId.map((el) => {
            url += `&teacherUserIdList=${el}`;
          })
        }
				classListIds.map((el) => {
					url += `&classIdList=${el}`;
				});
				request.type = 'GET';
				request.url = url;
				request.beforeSend = function (xhr) {
					xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
				};
				request.success = (res) => {
					resolve(res.results);
				}
				request.processData = false;
				$.ajax(request);
			// } else {
			// 	resolve([])
			// }
		})
	}

	function syncLikes() {
		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.coreApi}api/v2/Book/${MainData.bookId}/likedActivities`;
			let request = {};
			request.contentType = 'application/json';
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
				for (let activity of MainData.activities) {
					for (let act of res) {
						if (act.activityId == activity.activityId) {
							activity.isLike = act.isLike;
						}
					}
					if(MainData.activities.indexOf(activity) === MainData.activities.length - 1) {
						resolve(MainData.activities);
					}
				}
			}
			request.processData = true;
			$.ajax(request);
		})
	}

	function initInfoLayers() {
		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.pdfApi}activities?bookId=${MainData.bookId}`;
			let request = {};
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
				resolve(res.activities);
			}
			request.processData = false;
			$.ajax(request);
		})
	}

	function initBookNameAndBookmark() {
		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.coreApi}api/Book/GetBookByIdWithBookmark?BookId=${MainData.bookId}`
			let request = {};
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
				resolve(res.responseObject);
			}
			request.processData = false;
			$.ajax(request);
		})
	}

	function getBookmarksByBookId() {
		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.coreApi}api/v2/book/${MainData.bookId}/bookmark`
			let request = {};
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
				resolve(res);
			}
			request.error = (res) => {
				resolve([]);
			}
			request.processData = false;
			$.ajax(request);
		})
	}

	function initBookPages() {
		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.coreApi}api/Book/GetBookPages?BookId=${MainData.bookId}`
			let request = {};
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
				resolve(res.responseObject);
			}
			request.processData = false;
			$.ajax(request);
		})

	}

	function initBookChapters() {
		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.coreApi}api/BookChapter/GetBookChapterAndSubjects?BookId=${MainData.bookId}`;
			let request = {};
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
				resolve(res.responseObject);
			}
			request.processData = false;
			$.ajax(request);
		})
	}

	function lazyImgLoad() {
		const containerWrapper = document.querySelector(".conteiner-wrapper");
		const loaderWrapper = document.querySelector('.loader-material-wrapper');
		const spinner = loaderWrapper.querySelector('.spinner');
		const spinnerCloned = loaderWrapper.querySelector('.spinner').cloneNode(true);

		const zoomFactor = Math.floor(MainData.zoomFactor * 100) / 100;
		lazyloadPageImage();

		function lazyloadPageImage() {
			const lazyloadImages = document.querySelectorAll(".lazy");
			let scrollTop = containerWrapper.scrollTop + containerWrapper.offsetHeight;

			lazyloadImages.forEach(function(page) {
				var position = page.getBoundingClientRect();
				// if ((page.offsetTop + page.parentElement.offsetTop) < scrollTop
				// 	&& (page.offsetTop + page.parentElement.offsetTop) > (scrollTop - page.offsetHeight*2)) {

				if(position.top < window.innerHeight && position.bottom >= 0) {
						page.classList.remove('lazy');
						page.appendChild(spinnerCloned);
						let img = new Image();
						img.onload = function(){
							const el = page.querySelector('.spinner');
							if (el) page.removeChild(el);
							page.style.backgroundImage = `url(${page.dataset.src})`;
							page.removeAttribute('data-src');
						};
						img.src = page.dataset.src;
				}
			});
			if (lazyloadImages.length == 0) {
				containerWrapper.removeEventListener("scroll", lazyloadPageImage);
				window.removeEventListener("resize", lazyloadPageImage);
				window.removeEventListener("orientationChange", lazyloadPageImage);
			}
		}


		containerWrapper.addEventListener("scroll", lazyloadPageImage);
		window.addEventListener("resize", lazyloadPageImage);
		window.addEventListener("orientationChange", lazyloadPageImage);
	}

	function initCoordinates() {
		return new Promise((resolve, reject) => {
			$.get(`${MainData.environment.pdfApi}page?bookId=${MainData.bookId}`, function (windowDATA) {
				// for add custom words till module is not ready
				// windowDATA.map((page) => {
				// 	if (page.pageNumber == 2 && page.data) {
				// 		console.log(page.data);
				// 		const tempWord = [
				// 			{
				// 				"word": "www.hashbacha.org/arb",
				// 				"x": 570,
				// 				"y": 2169,
				// 				"w": 670,
				// 				"h": 58,
				// 				"index": 81,
				// 				"row": 19,
				// 				"max_y": 2173,
				// 				"max_h": 75,
				// 				"type": 2
				// 			}
				// 		];
				// 		page.data.push(...tempWord);
				// 		page.data.sort((a, b) => a.index - b.index);
				// 	}
				// })
				Promise.all([setPercentForCoords(windowDATA)]).then(() => {
					resolve(windowDATA);
					Marks.prototype.setMouseEvents(windowDATA);
				});
			});
		});
	}
	function getBookChaptersAndSubjectsWithExercises() {

		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.coreApi}api/v2/BookChapter/GetBookChaptersAndSubjectsWithExercises?bookId=${MainData.bookId}`;
			let request = {};
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
				console.log(res);
				resolve(res);
			}
			request.processData = false;
			$.ajax(request);
		})
	}

	function initBook() {
		return new Promise((resolve, reject) => {
			const url = `${MainData.environment.coreApi}api/Book/GetById?bookId=${MainData.bookId}`;
			let request = {};
			request.type = 'GET';
			request.url = url;
			request.beforeSend = function (xhr) {
				xhr.setRequestHeader("Authorization", `Bearer ${MainData.token}`);
			};
			request.success = (res) => {
				MainData.ministryOfEducationSerialCode = res.responseObject.ministryOfEducationSerialCode;
				resolve(res.responseObject);
			}
			request.processData = false;
			$.ajax(request);
		})
	}

	function setPercentForCoords(windowDATA) {
		return new Promise((resolve, reject) => {
			let img = new Image();
			console.log('setPercentForCoords');
			if (!MainData.originalPageSize) {
				img.onload = function () {
					MainData.originalPageSize = {
						width: this.width,
						height: this.height
					};
					windowDATA.forEach(page => {
						if (page.data && page.data.length) {
							page.data.forEach((word) => {
								if (word) {
									MainData.wordsEditor.setPercentWordCoords(word);
								}
							})
						}
					});
					const loaderWrapper = document.querySelector('.loader-material-wrapper');
					loaderWrapper.style.display = 'none';
					resolve(windowDATA);
				};
				if (windowDATA[0] && windowDATA[0].imagePath) {
					img.src = MainData.environment.media + windowDATA[0].imagePath;
				} else {
					const loaderWrapper = document.querySelector('.loader-material-wrapper');
					loaderWrapper.style.display = 'none';
					resolve(windowDATA);
				}
			} else {
				resolve(windowDATA);
			}
		});
	}

})();
